import "../../App.css";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaInfoCircle,
  FaInbox,
  FaInstagram,
  FaBehance,
  FaLinkedinIn,
  FaFacebookF,
  FaGithub,
} from "react-icons/fa";
import Modal from "react-bootstrap/Modal";

@inject("store")
@observer
class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.store.setInfoModal(false);
  }

  render() {
    const { store } = this.props;
    return (
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={store.showInfoModal}
        onHide={() => this.handleClose()}
        id="infoModal"
      >
        <Modal.Body>
          <div className="login-form-space">
            <h2>
              <FaInfoCircle /> About
            </h2>
            <hr className="small-underline" />
            <div className="info-text">
              <p>
                Create and share 3D design of home interiors in a quick and easy
                way. Share designs with clients with a single link which can be
                viewed on all devices without downloading any plugins. Allows
                for great flexibility in a post COVID work environment. <br />
                We support custom wall and floor textures and 3D models of
                furniture uploaded to your account exclusively.
                <br />
                <br />
              </p>
              <div className="inbox-container">
                <div className="inbox">
                  <FaInbox />
                </div>
                luminescence.feldspar@gmail.com
              </div>
              <div className="social-media-container">
                <div className="social-media-cell">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>@feldsparhomes</Tooltip>}
                  >
                    <a
                      target="_blank"
                      href="https://www.instagram.com/feldsparhomes/"
                      rel="noopener noreferrer"
                    >
                      <div className="social-media-button">
                        <div className="social-media-icon">
                          <FaInstagram />
                        </div>
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="social-media-cell">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>@feldsparhomes</Tooltip>}
                  >
                    <a
                      target="_blank"
                      href="https://www.facebook.com/feldsparhomes"
                      rel="noopener noreferrer"
                    >
                      <div className="social-media-button">
                        <div className="social-media-icon">
                          <FaFacebookF />
                        </div>
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="social-media-cell">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>@feldsparhomes</Tooltip>}
                  >
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/feldsparhomes/"
                      rel="noopener noreferrer"
                    >
                      <div className="social-media-button">
                        <div className="social-media-icon">
                          <FaLinkedinIn />
                        </div>
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="social-media-cell">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>@feldsparHomes</Tooltip>}
                  >
                    <a
                      target="_blank"
                      href="https://www.behance.net/feldsparhomes"
                      rel="noopener noreferrer"
                    >
                      <div className="social-media-button">
                        <div className="social-media-icon">
                          <FaBehance />
                        </div>
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
                <div className="social-media-cell">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>@Luminescense-Studios</Tooltip>}
                  >
                    <a
                      target="_blank"
                      href="https://github.com/Luminescense-Studios/Feldspar"
                      rel="noopener noreferrer"
                    >
                      <div className="social-media-button">
                        <div className="social-media-icon">
                          <FaGithub />
                        </div>
                      </div>
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoginModal;
