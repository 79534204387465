import "../App.css";
import React, { Component } from "react";
import { useMediaQuery } from "react-responsive";
import Button from "react-bootstrap/Button";
import { FaTrashAlt, FaClone } from "react-icons/fa";
import { BASE_URL, ASSETS, MIRROR_ITEM_ICON } from "../Constants.js";
import { inject, observer } from "mobx-react";

const ContextMenu = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <div>
      {(isDesktopOrLaptop || !isPortrait) && (
        <div>
          <span id="context-menu-name" className="lead">
            {isDesktopOrLaptop}
          </span>
          <Button
            variant="danger"
            size="sm"
            block
            id="context-menu-delete"
            className="outline-button"
          >
            <span className="icon-centre">
              <FaTrashAlt />
            </span>
            <span className="text-centre">Delete Item</span>
          </Button>
          <Button
            variant="danger"
            size="sm"
            block
            id="context-menu-mirrorize"
            className="outline-button"
          >
            <span className="icon-centre-svg">
              <img
                className="icon-svg"
                src={BASE_URL + ASSETS + MIRROR_ITEM_ICON}
                alt=""
              />
            </span>
            <span className="text-centre">Mirror</span>
          </Button>
          <Button
            variant="danger"
            size="sm"
            block
            id="context-menu-duplicate"
            className="outline-button add-item"
          >
            <span className="icon-centre-svg">
              <FaClone />
            </span>
            <span className="text-centre">Duplicate</span>
          </Button>
          <div className="panel">
            <div className="panel-heading">Adjust Size</div>
            <hr className="small-underline" />
            <div className="panel-body">
              <div className="form-horizontal">
                <div className="form-group">
                  <label className="control-label">Width</label>

                  <input
                    type="number"
                    className="form-control"
                    id="item-width"
                    min="1"
                  ></input>
                </div>
                <div className="form-group">
                  <label className="control-label">Depth</label>

                  <input
                    type="number"
                    className="form-control"
                    id="item-depth"
                    min="1"
                  ></input>
                </div>
                <div className="form-group" id="item-height-div">
                  <label className="control-label">Height</label>

                  <input
                    type="number"
                    className="form-control"
                    id="item-height"
                    min="1"
                  ></input>
                </div>
                <div className="form-group" id="item-elevation-div">
                  <label className="control-label">Elevation</label>

                  <input
                    type="number"
                    className="form-control"
                    id="item-elevation"
                    min="0"
                  ></input>
                </div>
              </div>
              <small>
                <span>Measurements in inches.</span>
              </small>
            </div>
          </div>
          <input type="checkbox" id="fixed" />{" "}
          <label htmlFor="fixed">Lock in place</label>
        </div>
      )}
    </div>
  );
};

export default ContextMenu;
