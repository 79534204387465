// CONTACT DETAILS
export const FELDSPAR = "Feldspar"
export const EMAIL = "luminescence.feldspar@gmail.com"
export const INSTAGRAM_HANDLE = "@feldsparhomes"
export const INSTAGRAM_PAGE = "https://www.instagram.com/feldsparhomes/"
export const FACEBOOK_HANDLE = "@feldsparhomes"
export const FACEBOOK_PAGE = "https://www.facebook.com/feldsparhomes"
export const BEHANCE_HANDLE = "@FeldsparHomes"
export const BEHANCE_PAGE = "https://www.behance.net/feldsparhomes"
export const GITHUB_HANDLE = "@Luminescense-Studios"
export const GITHUB_PAGE = "https://github.com/Luminescense-Studios/Feldspar"
export const LINKEDIN_HANDLE = "@feldsparhomes"
export const LINKEDIN_PAGE = "https://www.linkedin.com/company/feldsparhomes/"


// SERVER
export const HOST_URL = "https://www.feldsparhomes.com/";
export const BASE_URL = "https://www.feldsparhomes.com:8001/";
export const BASE_URL_AUTH = "https://www.feldsparhomes.com:8000/";
export const DEFAULT_WALL_MAP = "5f0dde8ee5148d0ef82ff069";
export const DEFAULT_FLOOR_MAP = "5f0dddf4e5148d0ef82ff066";
export const LOGO_NO_MOON = "5f5b4466bde15a044b226a5f";
export const LOGO_WHITE_NO_MOON = "5fa02d2359425f0416dded32";
export const IMAGE_FURNITURE_PLACEHOLDER = "5f71fd590ef99d0432c231ff";
export const MIRROR_ITEM_ICON = "5f775880eaa34a044178da19";

// DEV-LOCAL
// export const HOST_URL = "http://localhost:3000/";
// export const BASE_URL = "http://localhost:8001/";
// export const BASE_URL_AUTH = "http://localhost:8000/";
// export const DEFAULT_WALL_MAP = "5f07f740b5991623548246d7"
// export const DEFAULT_FLOOR_MAP = "5ef849c28188602a98c95524"

// GOOGLE TRACKING-ID-----------------------------------------------
export const TRACKING_ID = "UA-183749329-1";
export const GA_OPTIONS = {
    debug: true,
    titleCase: false,
}
export const INSTAGRAM_OUTBOUND = "instagram"
export const FACEBOOK_OUTBOUND = "facebook"
export const BEHANCE_OUTBOUND = "behance"
export const GITHUB_OUTBOUND = "github"
export const LINKEDIN_OUTBOUND = "linkedin"

// COMMON-----------------------------------------------------------
export const ASSETS = "assets/";
export const MODELS = "models/";
export const FLOOR_MODELS = "floorModel/"
export const TEXTURES = "textures/";
export const RETRIEVE = "retrieve/";
export const USERS = "users/";
export const RESOURCES = "resources/";
export const FIND = "find/";
export const VIEW_MODEL = "view/"
export const CONTACT_MESSAGE = "contactMessage/"

export const REGISTER = "register";
export const LOGIN = "login";
export const LOGIN_WITH_GOOGLE = "loginWithGoogle";
export const LOGOUT = "logout";
export const UPLOAD_ASSET = "uploadAsset"
export const UPDATE_ASSET_ENTRY = "updateAssetEntry"
export const CREATE = "create"

export const SOFA_CATEGORY = "sofa";
export const CHAIR_CATEGORY = "chair";
export const BED_CATEGORY = "bed";
export const ARCH_CATEGORY = "architectural";
export const MISC_CATEGORY = "misc";
export const KITCHEN_CATEGORY = "kitchen";
export const RUG_CATEGORY = "rug";
export const TABLE_CATEGORY = "table";
export const CABINET_CATEGORY = "cabinet";
export const CURTAIN_CATEGORY = "curtain";
export const LIGHT_CATEGORY = "light";
export const BATH_CATEGORY = "bath";
export const FLOOR_CATEGORY = "floor";
export const FLOOR_WOOD_CATEGORY = "floorWood";
export const FLOOR_MARBLE_CATEGORY = "floorMarble";
export const FLOOR_TILE_CATEGORY = "floorTile";
export const WALL_CATEGORY = "wall";
export const WALL_SOLID_CATEGORY = "solid";
export const WALL_TILE_CATEGORY = "wallTile";
export const GET_FREE_RESOURCES = "getFreeResources";
export const GET_RESOURCES = "getResources"

export const REMOVE_MODEL = "removeModel"
export const RETRIEVE_MODEL = "retrieve"
export const UPDATE_MODEL = "update"